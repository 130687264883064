<script>
import embed from 'vega-embed';

export default {
  name: 'AllelicSeriesPlot',
  props: {
    records: {
      type: Array,
      default: () => ([
        { pp: 0.0, beta: 0.0, variant: 'NA', gene: 'NA', trait: 'NA', tissue: 'NA' },
      ])
    }
  },
  data() {
    const vegaDef = {
      $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
      data: {
        values: this.records,
      },
      padding: 15,
      autosize: 'pad',
      hconcat: [],
      config: {
        autosize: 'pad',
        view: {
          stroke: null,
        },
        concat: {
          spacing: 25,
        },
        legend: {
          offset: 50,
          padding: 5,
          layout: {
            right: {
              anchor: 'middle',
            },
          },
        },
      },
    };

    return {
      def: vegaDef,
      plotData: this.records
    };
  },
  methods: {
    addRecord(record) {
      this.plotData.push(record);
    },
    updateDef() {
      const columns = Object.getOwnPropertyNames(this.records[0]).filter((w) => !['pp', 'beta', 'variant', '__ob__'].includes(w));
      const template = {
        mark: {
          type: 'text',
          fontSize: 14,
          baseline: 'middle',
          align: 'center',
        },
        height: {
          step: 50,
        },
        encoding: {
          y: {
            field: 'key',
            type: 'nominal',
            axis: {
              title: '',
              titleAngle: 0,
              titleAlign: 'center',
              titleFontSize: 14,
              titleY: -1,
              titleX: 10,
              labelExpr: '',
              grid: false,
              domain: false,
              ticks: false,
            },
          },
          text: {
            field: '',
            type: 'nominal',
          },
        },
      };

      this.def.hconcat = [];

      for (const col of columns.slice().reverse()) {
        const tmp = structuredClone(template);
        tmp.encoding.y.axis.title = col.charAt(0).toUpperCase() + col.slice(1); // capitalize
        tmp.encoding.text.field = col;
        this.def.hconcat.unshift(tmp);
      }

      const bubblePlot = {
        mark: 'circle',
        width: {
          step: 50,
        },
        height: {
          step: 50,
        },
        encoding: {
          x: {
            field: 'variant',
            type: 'nominal',
            axis: {
              grid: false,
              domain: false,
              orient: 'top',
              title: null,
              labelAngle: 270,
              labelPadding: 15,
              labelFontSize: 14,
              ticks: false,
            },
          },
          y: {
            field: 'key',
            type: 'nominal',
            axis: {
              domain: false,
              offset: 15,
              labelFontSize: 14,
              labelExpr: null,
              ticks: false,
              grid: false,
              title: '',
              titleAlign: 'center',
              titleAngle: 0,
              titleBaseline: 'top',
              titleY: -10,
              titleX: -20,
              titleFontSize: 13,
            },
          },
          size: {
            field: 'pp',
            type: 'quantitative',
            title: 'Coloc H4',
          },
          color: {
            field: 'beta',
            type: 'quantitative',
            title: 'QTL Effect Size',
            scale: {
              scheme: 'purplegreen',
            },
          },
        },
      };

      this.def.hconcat.push(bubblePlot);

      const cols_joined = columns.map((e) => `datum.${e}`).join(` + ',' + `);
      const transform = [
        {
          calculate: cols_joined,
          as: 'key',
        },
      ];

      this.def.transform = transform;
    },
    draw() {
      embed('#allelic-series', this.def);
    }
  },
  watch: {
    plotData(value) {
      this.updateDef();
      this.draw();
    }
  },
  mounted() {
    this.updateDef();
    this.draw();
  }
};
</script>

<template>
  <div id="allelic-series"></div>
</template>

<style scoped></style>
