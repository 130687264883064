<script>
export default {
  name: 'StudyLabel',
  props: {
    study_name: { type: String, required: true },
    abbrev: { type: Boolean, default: true },
  },
  data() {
    return {
      labelLength: 12
    };
  },
  methods: {
    formatLabel(s) {
      if (this.abbrev && s.length > this.labelLength) {
        return `${s.slice(0, this.labelLength)}...`;
      } else {
        return s;
      }
    }
  }
};
</script>

<style scoped>

</style>

<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        {{ formatLabel(study_name) }}
      </span>
    </template>
    <span>
      {{ study_name }}
    </span>
  </v-tooltip>
</template>
