import { PORTALDEV_API_URL } from '@/constants';

function fetchJson (url, init) {
  // Fetch helper method that also handles errors
  return fetch(url, init).then((resp) => {
    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    return resp.json();
  });
}

/**
 * Convert a gene symbol to a coordinate match with coordinates ({start, end}), or else return null
 * @param gene_symbol
 * @param genome_build
 * @return {Promise<*>}
 */
function gene_symbol_to_region(gene_symbol, genome_build) {
  const base = new URL(`${PORTALDEV_API_URL}/annotation/omnisearch/`);
  base.searchParams.set('q', gene_symbol);
  base.searchParams.set('build', genome_build);
  return fetchJson(base)
    // Eliminate other query types that the API provides, as well as error messages
    // Resolve to either a match object, or null
    .then((data) => data.data.filter((item) => !item.error && (item.type !== 'region')))
    .then((search_results) => search_results.find((item) => gene_symbol === item.term));
}

/**
 * A tagged template function that encodes URL parameters used in path segments / query params
 *   Usage: url`https://website.example/${value1}/?param=${value2}`
 *   Query params can be handled completely via the standard `URL` interface in JS; this is useful mainly for initial encoding of path segments
 * @param strings
 * @param values
 */
function url(strings, ...values) {
  let res = '';
  strings.forEach((seg, i) => {
    res += seg + encodeURIComponent((values[i] || ''));
  });
  return res;
}

function formatVariant(variant, truncateLength = 0) {
  // Split the variant into its components
  const components = variant.split('_');

  // Convert the position (the second element in the array) to a locale string
  components[1] = parseInt(components[1]).toLocaleString();

  // Join the components back together with underscores
  let formattedVariant = components.join('_');

  if (truncateLength) {
    if (formattedVariant.length > truncateLength) {
      formattedVariant = `${formattedVariant.substr(0, truncateLength - 3)}...`;
    }
  }

  // Return the formatted variant
  return formattedVariant;
}

export { fetchJson, gene_symbol_to_region, url, formatVariant };
