import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import VueGtag from 'vue-gtag';

import './assets/css/main.css';

Vue.config.productionTip = false;

if (process.env.VUE_APP_GA_ID) {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GA_ID
    },
    pageTrackerScreenviewEnabled: true
  }, router);
}

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
