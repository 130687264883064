<template>

  <div class="d-flex align-center mt-1">
    <router-link :to="{name: 'home'}">
      <img
          alt="CoLocus"
          src="../../assets/logo.png"
          height="35"
      />
    </router-link>
  </div>

</template>

<script>
export default {
  name: 'AppLogo',
};

</script>
