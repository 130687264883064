<script>
import { fetchJson, url } from '@/util/util';
import ColocResultsTable from '@/components/widgets/ColocResultsTable';
import ColocResultQuery, { coloc_query_url } from '@/mixins/ColocResultQuery';
import { debounce } from 'lodash';
import { ITEMS_PER_PAGE, TRAIT_TYPES, POS_DEC_REGEX } from '@/constants';
import { mapGetters, mapActions } from 'vuex';
import DOMPurify from 'dompurify';

const REGEX_REGION = /^(?:chr)?([0-9a-zA-Z]+):(\d+)-(\d+)$/;

function parse_nearness(value) {
  // Very simplistic heuristic for now: if it isn't a position, it's a gene
  const match = REGEX_REGION.exec(value);

  if (match) {
    // eslint-disable-next-line no-unused-vars
    const [_, chrom, start, end] = match;
    return [
      ['signal1__lead_variant_chrom', chrom],
      ['signal1__lead_variant_pos__gte', start],
      ['signal1__lead_variant_pos__lte', end],
    ];
  }
}

function getUIData(study_id) {
  return fetchJson(url`/api/internal/studies/${study_id}/search_metadata/`);
}

/**
 * Search page: see top results for all signal pairs in the entire study
 */
export default {
  name: 'SearchView',
  components: { ColocResultsTable },
  mixins: [ColocResultQuery],
  // FIXME: catch errors/ handle route enter failures
  beforeRouteEnter (to, from, next) {
    getUIData(to.params.study_id)
      .then((result) => next((vm) => vm.setUIData(result)));
  },
  beforeRouteUpdate(to, from) {
    getUIData(to.params.study_id)
      .then((result) => this.setUIData(result));
  },
  beforeDestroy() {
    // Moved to beforeDestroy from beforeRouteLeave - this is called when the user navigates away from the page, but if
    // the route actually comes back to this same view (clicking on colocus logo), then the component is not re-created
    // and so enableFilterButton() is not called. The effect is the filter button disappears from the page.
    this.disableFilterButton();
  },
  created() {
    this.enableFilterButton();
  },
  mounted() {
    // If the user passes a gene or list of genes as a query parameter in the URL, pre-populate the gene filter
    let genes = this.$route.query.gene;
    if (genes) {
      // If it was a single gene, we still want to end up with an array.
      if (!Array.isArray(genes)) {
        genes = [genes];
      }

      // Now we need to check if this was a valid gene. Otherwise, it can't be added to the autocomplete box, but the
      // interface will be stuck without a way to clear it.
      const found_genes = [];
      const missing_genes = [];
      for (const g of genes) {
        if (this.all_genes.some((gene) => gene.startsWith(g))) {
          found_genes.push(g);
        } else {
          missing_genes.push(g);
        }
      }
      this.selected_genes = found_genes;
      if (missing_genes.length) {
        this.showError(`<b>${missing_genes.join(', ')}</b> not found`);
      }
    }
  },
  data() {
    const filterData = JSON.parse(JSON.stringify(this.$store.getters.getFilterData));
    return {
      // Fetch these from a purpose-built API endpoint to power this view (internal-only)
      // all_studies: ['brotman'],  // TODO future
      all_pairs_count: 0, // Tell the user how many distinct signal pairs there are across the entire dataset
      phenotypes: [],
      tissues: [],
      trait_types: [],
      studies: [],
      all_genes: [],

      // UI toggles
      // NOTE: list of tuples, because each Vue combobox item has a format (uuid, label)
      // selected_study: 'brotman', // TODO: In future, support more than one study
      selected_phenotypes: filterData.selected_phenotypes,
      selected_tissues: filterData.selected_tissues, // select from a list of all available QTL types
      selected_studies: filterData.selected_studies,
      selected_genes: filterData.selected_genes,
      near_region: filterData.near_region,
      filter_h4: filterData.filter_h4, // 0.5
      filter_r2: filterData.filter_r2, // 0.3
      filter_trait1_logp: filterData.filter_trait1_logp,
      filter_trait2_logp: filterData.filter_trait2_logp,
      show_ensg: false,
      show_effects: false,

      // Validation rules for text inputs
      rules: {
        log_pvalue: (v) => (!!v && POS_DEC_REGEX.test(v)),
        probability: (v) => (!!v && POS_DEC_REGEX.test(v) && v >= 0 && v <= 1)
      },

      // For error message
      alertVisible: false,
      errorMessageLines: []
    };
  },
  computed: {
    TRAIT_TYPES() {
      return TRAIT_TYPES;
    },
    ...mapGetters(['isFilterPanelVisible', 'getFilterData']),
    table_url() {
      // Build the search URL from page options, including user-specified search filters
      const base = coloc_query_url.bind(this)(this.$route.params.study_id);

      if (this.selected_phenotypes.length) {
        // NOTE: Vue combobox items are format (uuid, label)
        const choices = this.selected_phenotypes.join(',');
        base.searchParams.set('phenotypes', choices);
      }

      if (this.selected_tissues.length) {
        const choices = this.selected_tissues.join(',');
        base.searchParams.set('tissues', choices);
      }

      if (this.selected_studies.length) {
        const choices = this.selected_studies.join(',');
        base.searchParams.set('studies', choices);
      }

      if (this.selected_genes) {
        base.searchParams.set('genes', this.selected_genes);
      }

      if (this.near_region) {
        const params = parse_nearness(this.near_region);
        if (params) {
          params.forEach(([k, v]) => base.searchParams.set(k, v));
        }
      }

      if (this.filter_h4 > 0.0) {
        base.searchParams.set('coloc_h4__gte', this.filter_h4);
      }

      if (this.filter_r2 > 0.0) {
        base.searchParams.set('r2__gte', this.filter_r2);
      }

      if (this.filter_trait1_logp > 0.0) {
        base.searchParams.set('signal1__lead_variant_neg_log_p__gte', this.filter_trait1_logp);
      }

      if (this.filter_trait2_logp > 0.0) {
        base.searchParams.set('signal2__lead_variant_neg_log_p__gte', this.filter_trait2_logp);
      }

      base.searchParams.set('page_size', String(ITEMS_PER_PAGE));

      return base;
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(['enableFilterButton', 'disableFilterButton', 'setFilterDataPoint']),
    setUIData(data) {
      // The search UI dropdowns ("filter by available categories") are fetched from a custom API endpoint
      const { count_pairs, trait_types, phenotypes, tissues, studies, genes } = data;
      this.tissues = tissues.sort();
      this.all_pairs_count = count_pairs;
      this.trait_types = trait_types;
      this.phenotypes = phenotypes.sort();
      this.studies = studies.sort();
      this.all_genes = genes;
    },
    debounceInput: debounce(function(arg, value) {
      this[arg] = value;
      this.setFilterDataPoint({ dataKey: arg, dataValue: value });
    }, 500),
    getColumnWidth: (isFilterPanelVisible) => {
      return isFilterPanelVisible ? 10 : 12;
    },
    geneMatchLowercase(item, queryText, itemText) {
      return itemText.toLowerCase().startsWith(queryText.toLowerCase());
    },
    showError(message) {
      this.errorMessageLines.push(DOMPurify.sanitize(message));
      this.alertVisible = true;

      // setTimeout(() => {
      //   this.alertVisible = false;
      // }, 5000); // Alert will hide after 3000ms (3 seconds)
    },
  },
};
</script>

<template>
  <v-container :fluid="true">
    <v-row>
      <v-col class="grey lighten-5" md="2" style="height: 100vh" v-if="isFilterPanelVisible">
        <h2 class="my-4">Select</h2>

        <div >
          <h3>Study</h3>
          <v-combobox
            :items="studies"
            v-model="selected_studies"
            @change="(v) => setFilterDataPoint({ dataKey: 'selected_studies', dataValue: v })"
            multiple
            dense
            outlined
            small-chips
            deletable-chips
            clearable
            background-color="white"
          />
        </div>

        <div v-if="trait_types.includes(TRAIT_TYPES.EQTL)">
          <h3>QTL Gene
            <v-tooltip right color="green" open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    medium
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Start typing to see matches, then select one or more with mouse or arrow keys and enter</span>
            </v-tooltip>

          </h3>
          <v-autocomplete
            v-model="selected_genes"
            :items="all_genes"
            :filter="geneMatchLowercase"
            @change="(v) => setFilterDataPoint({ dataKey: 'selected_genes', dataValue: v })"
            multiple
            small-chips
            deletable-chips
            clearable
            outlined
            dense
            background-color="white"
            placeholder="Search for a gene by name"
          ></v-autocomplete>
        </div>

        <h3>Genomic Region</h3>
        <v-text-field
          :value="near_region"
          @input="v => debounceInput('near_region', v)"
          outlined
          dense
          background-color="white"
          placeholder="chr:start-end"/>

        <div v-if="trait_types.includes(TRAIT_TYPES.GWAS)">
          <h3>GWAS Phenotype</h3>
          <v-combobox
            :items="phenotypes"
            v-model="selected_phenotypes"
            @change="(v) => setFilterDataPoint({ dataKey: 'selected_phenotypes', dataValue: v })"
            multiple
            dense
            outlined
            small-chips
            deletable-chips
            clearable
            background-color="white"
          />
        </div>

        <div v-if="trait_types.includes(TRAIT_TYPES.EQTL)">
          <h3>QTL Tissue</h3>
          <v-combobox
            :items="tissues"
            v-model="selected_tissues"
            @change="(v) => setFilterDataPoint({ dataKey: 'selected_tissues', dataValue: v })"
            multiple
            dense
            outlined
            small-chips
            deletable-chips
            clearable
            background-color="white"
          />
        </div>

        <v-divider />
        <h2 class="my-4">Set threshold</h2>

        <h3>Trait 1 -log<sub>10</sub> p-value ≥</h3>
        <v-text-field
          :value="filter_trait1_logp"
          @input="v => debounceInput('filter_trait1_logp', v)"
          :rules="[rules.log_pvalue]"
          outlined
          dense
          background-color="white"
          type="number"
          hide-spin-buttons
        />

        <h3>Trait 2 -log<sub>10</sub> p-value ≥</h3>
        <v-text-field
          :value="filter_trait2_logp"
          @input="v => debounceInput('filter_trait2_logp', v)"
          :rules="[rules.log_pvalue]"
          outlined
          dense
          background-color="white"
          type="number"
          hide-spin-buttons
        />

        <h3>Colocalization PP(H<sub>4</sub>) ≥</h3>
        <v-text-field
          :value="filter_h4"
          @input="v => debounceInput('filter_h4', v)"
          :rules="[rules.probability]"
          outlined
          dense
          background-color="white"
          type="number"
          hide-spin-buttons
        />

        <h3>R<sup>2</sup> ≥</h3>
        <v-text-field
          :value="filter_r2"
          @input="v => debounceInput('filter_r2', v)"
          :rules="[rules.probability]"
          outlined
          dense
          background-color="white"
          type="number"
          hide-spin-buttons
        />

        <v-divider />
        <h2 class="my-4">View</h2>
          <v-checkbox class="ma-0 pa-0" label="Show Ensembl IDs" v-model="show_ensg"></v-checkbox>
          <v-checkbox class="ma-0 pa-0" label="Show effect sizes" v-model="show_effects"></v-checkbox>
      </v-col>

      <v-col :md="getColumnWidth(isFilterPanelVisible)" class="pa-5">
        <v-alert
          v-model="alertVisible"
          type="warning"
          dismissible
          @input="vis => { if (!vis) errorMessageLines = []; }"
        >
          <!-- Please use this.showErrorMessage() to set the message lines that will be displayed here -->
          <div v-for="(line, index) in errorMessageLines" :key="index" v-html="line"></div>
        </v-alert>

        <h1>Top colocalization results</h1>
        <p>
          See top colocalization results across all signal pairs for any combination of traits. Use the filters at left to narrow your search. Click on a row to see LocusZoom plots for that result. You are viewing <span class="blue--text text--darken-4">{{table_result_count}}</span> of the <span class="indigo--text text--darken--4">{{all_pairs_count}}</span> total signal pairs in this dataset.
        </p>
        <coloc-results-table
          :show_trait1="true"
          :abbrev_trait="true"
          :show_ensg="show_ensg"
          :show_effects="show_effects"
          :items="table_data"
          :options.sync="table_options"
          :server-items-length="table_result_count"
          :loading="loading_table"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .counter {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1rem;
  }
</style>
