<script>
/**
 * A summary view showing a bubble or strip plot.
 *
 * For a given trait, show all results in a region nearest that trait.
 */

import AllelicSeriesPlot from '@/components/widgets/AllelicSeriesPlot';
import { coloc_results_list } from '@/util/urls';
import { fetchJson, gene_symbol_to_region } from '@/util/util';
import { NEARBY_DIST } from '@/constants';

/***
 *
 * @param study_id
 * @param trait_id
 * @param route_query {Object} Router object providing query params.  `this.$route.query` or `to.query`
 * @return {Promise}
 */
function getData(study_id, trait_id, route_query) {
  const { gene, chrom, start, end } = route_query;

  const base = coloc_results_list(study_id);
  // Ask for the most data we are allowed to get within this region. TODO: revisit if we need to depaginate, this query could get ugly
  base.searchParams.set('page_size', 250);

  base.searchParams.set('signal1__trait__uuid', trait_id);

  // We only want our summary to show items where there is significant evidence of colocalization- TODO revise cutoff
  base.searchParams.set('coloc_h4__gte', 0.5);

  // For AS series plot, want variants to come back in sorted order of QTL variant
  base.searchParams.set('ordering', 'signal2__lead_variant_chrom,signal2__lead_variant_pos');

  if (gene) {
    // This only allows an exact gene
    base.searchParams.set('signal1__lead_variant_nearest_gene', gene);
  } else if (chrom && start && end) {
    // "Nearest" gene to a variant is not always the gene of action. Show effects on other nearby genes by allowing a
    //   narrow "Region query". Ask Fauman a recommended distance.
    if ((start >= end) || ((end - start) > NEARBY_DIST)) {
      return Promise.reject(new Error(`Invalid region query (max allowed size is ${NEARBY_DIST})`));
    }
    base.searchParams.set('signal1__lead_variant_chrom', chrom);
    base.searchParams.set('signal1__lead_variant_pos__gte', start);
    base.searchParams.set('signal1__lead_variant_pos__lte', end);
  } else {
    return Promise.reject(new Error('Unrecognized query. Must provide either `?gene` or `?start=X&end=Y`'));
  }
  return fetchJson(base);
}

/**
 * Show a bubble plot that allows comparing the evidence for various lead SNPs based on nearest gene
 *   (or within some region)
 */
export default {
  name: 'TraitGeneRegionView',
  components: { AllelicSeriesPlot },
  data() {
    return {
      coloc_results: [],
      as_results: [],

      show_error: false,
      error_message: '',
    };
  },
  computed: {
    gene() {
      return this.$route.query.gene;
    },
    region() {
      const { chrom, start, end } = this.$route.query;
      return `${chrom}:${start}-${end}`;
    }
  },
  beforeRouteEnter(to, from, next) {
    getData(to.params.study_id, to.params.trait_id, to.query)
      .then((data) => next((vm) => vm.setData(data)));
  },
  beforeRouteUpdate (to, from, next) {
    getData(to.params.study_id, to.params.trait_id, to.query)
      .then((data) => {
        this.setData(data);
        next();
      });
  },
  methods: {
    toggleToRegion() {
      if (!this.gene) {
        // Shouldn't be possible, as UI will only show this button in one direction
        return;
      }

      // FIXME: How do we provide build information? Is this intrinsic to the study? The app?
      gene_symbol_to_region(this.gene, 'GRCh37')
        .then((match) => {
          if (!match) {
            throw new Error('No match found');
          }
          const { chrom, start, end } = match;
          this.$router.push({ query: { chrom, start, end } });
        }).catch((err) => {
          this.show_error = true;
          this.error_message = err.message;
        });
    },
    setData(data) {
      this.coloc_results = data;
      for (const result of data.results) {
        this.as_results.push({
          pp: result.coloc_h4,
          beta: result.signal2.lead_variant_effect,
          variant: result.signal2.lead_variant_marker,
          gene: result.signal2.lead_variant_assoc_gene,
          tissue: result.signal2.trait.metadata.tissue
        });
      }
    }
  }
};
</script>

<template>
  <v-container :fluid="true">
      <v-row>
        <v-col cols="12">
          <h1>Allelic series near <span class="indigo--text text--darken--4" v-if="gene">{{gene}}</span><span class="indigo--text text--darken--4" v-else>{{region}}</span></h1>
        </v-col>
      </v-row>

    <div v-if="gene">
      <v-btn @click="toggleToRegion">Switch to Region View</v-btn>
    </div>
    <br>

<!--    <div>-->
<!--      <v-btn @click="$refs.asplot.addRecord({ pp: 0.89, beta: -1.48, variant: 'rs4', gene: 'GG1', trait: 'FGLU', tissue: 'Pancreas' })">Try adding record</v-btn>-->
<!--    </div>-->

    <p v-if="!coloc_results.count">No results found</p>
    <div v-else>
      <allelic-series-plot ref="asplot" :records="as_results"></allelic-series-plot>
    </div>

    <!-- Error display for data retrieval, etc. -->
    <v-snackbar
      v-model="show_error"
      app top right
      :timeout="4000">
      {{ error_message }}
    </v-snackbar>
  </v-container>
</template>

<style scoped>
 .v-btn {
   margin: 5px;
 }
</style>
