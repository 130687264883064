import { render, staticRenderFns } from "./TraitLabel.vue?vue&type=template&id=4a3a3f2b&scoped=true"
import script from "./TraitLabel.vue?vue&type=script&lang=js"
export * from "./TraitLabel.vue?vue&type=script&lang=js"
import style0 from "./TraitLabel.vue?vue&type=style&index=0&id=4a3a3f2b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3a3f2b",
  null
  
)

export default component.exports