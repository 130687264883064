import { render, staticRenderFns } from "./ColocResultsTable.vue?vue&type=template&id=730b1afa&scoped=true"
import script from "./ColocResultsTable.vue?vue&type=script&lang=js"
export * from "./ColocResultsTable.vue?vue&type=script&lang=js"
import style0 from "./ColocResultsTable.vue?vue&type=style&index=0&id=730b1afa&prod&lang=css"
import style1 from "./ColocResultsTable.vue?vue&type=style&index=1&id=730b1afa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "730b1afa",
  null
  
)

export default component.exports