<script>
/**
 * Basic information about a particular study / group of analyses
 */
export default {
  name: 'StudyDetailView',
};
</script>

<template>
  <v-container :fluid="true">
    <v-row>
      <v-col cols="12">
        <h1></h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
