import { url } from '@/util/util';

/**
 * Construct default API query URL
 * @param study_id
 * @return {module:url.URL} Returns a URL object that can be extended via `URL.searchParams.set(k, v)`
 */
function coloc_results_list(study_id) {
  return new URL(url`/api/studies/${study_id}/coloc/`, window.location.origin);
}

export { coloc_results_list };
