<script>
/**
 * A Manhattan plot style view that shows all possible colocalization results for a single GWAS with any other line of supporting evidence
 */
import { fetchJson, url } from '@/util/util';
import ManhattanPlot from '@/components/widgets/ManhattanPlot';
import ColocResultsTable from '@/components/widgets/ColocResultsTable';
import ColocResultQuery, { coloc_query_url } from '@/mixins/ColocResultQuery';
import { TRAIT_TYPES } from '@/constants';
import { mapGetters } from 'vuex';

function getData(study_id, trait_id) {
  // Table loaded separately after rest of page has rendered
  const trait_detail = url`/api/studies/${study_id}/traits/${trait_id}/`;
  const manhattan_json = url`/api/internal/studies/${study_id}/traits/${trait_id}/manhattan/`;

  return Promise.all([
    fetchJson(trait_detail),
    fetchJson(manhattan_json),
  ]).then((page_data) => {
    // Prevent users from url-hacking to get a "manhattan plot" of QTL data- the traits landing page only has trait1 as a GWAS
    // Strictly speaking, the API would let this query be generated, so we check after server responds.
    if (page_data[0].trait_type !== TRAIT_TYPES.GWAS) {
      throw new Error('Manhattan plot view is only available for GWAS-type traits');
    }
    return page_data;
  });
}

/**
 * Show a manhattan plot for one trait plus all signals associated with that trait.
 */
export default {
  name: 'TraitManhattanSignalsView',
  components: { ColocResultsTable, ManhattanPlot },
  mixins: [ColocResultQuery],
  beforeRouteEnter (to, from, next) {
    getData(to.params.study_id, to.params.trait_id)
      // FIXME: catch errors/ handle route enter failure
      .then((page_data) => next((vm) => vm.setData(page_data)));
  },
  beforeRouteUpdate (to, from) {
    getData(to.params.study_id, to.params.trait_id)
      // FIXME: catch errors/ handle route enter failure
      .then((page_data) => this.setData(page_data));
  },
  created() {
    this.selected_signals = {};
  },
  mounted() {
    this.fetchTableData();
  },
  data() {
    return {
      is_loaded: false,

      /// REST data for the page (comes from 3 endpoints)
      // Trait + analysis metadata. Used to render labels and provenance.
      trait_detail: {},
      // Manhattan plot JSON
      manhattan_json: null,
      // Note: Additional table data properties provided by mixin

      // Controls for features
      selected_signal_count: 0
    };
  },
  computed: {
    ...mapGetters(['getFilterData']),
    table_url() {
      const base = coloc_query_url.bind(this)(this.$route.params.study_id);
      base.searchParams.set('trait_uuid', this.$route.params.trait_id);

      const filter_data = this.getFilterData;
      base.searchParams.set('coloc_h4__gte', filter_data.filter_h4);
      base.searchParams.set('r2__gte', filter_data.filter_r2);
      base.searchParams.set('signal1__lead_variant_neg_log_p__gte', filter_data.filter_trait1_logp);
      base.searchParams.set('signal2__lead_variant_neg_log_p__gte', filter_data.filter_trait2_logp);

      if (this.selected_signal_count) {
        const uuids = Object.getOwnPropertyNames(this.selected_signals);
        base.searchParams.set('signals', uuids);
      }
      return base;
    }
  },
  methods: {
    setData([trait_detail, manhattan_json]) {
      this.trait_detail = trait_detail;
      this.manhattan_json = manhattan_json;

      this.is_loaded = true;
    },
    addSignals(signals) {
      for (const s of signals) {
        this.selected_signals[s.signal_uuid] = s;
      }
      this.selected_signal_count = Object.keys(this.selected_signals).length;
    },
    removeSignal(signal) {
      delete this.selected_signals[signal.signal_uuid];
      this.selected_signal_count = Object.keys(this.selected_signals).length;
    },
    clearSignals() {
      this.selected_signals = {};
      this.selected_signal_count = 0;
    }
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader
      v-if="!is_loaded"
      class="mx-auto pa-md-3" type="table-tfoot, card"/>

    <v-container :fluid="true" v-else>
      <h1>All colocalization results for <span class="indigo--text text--darken--4">{{ trait_detail.metadata.trait }}</span></h1>
      <p>
        {{trait_detail.study_name}} - {{trait_detail.authors}}
        <span v-if="trait_detail.external_link">(<a :href="trait_detail.external_link" target="_blank">details</a>)</span>
        <span v-if="trait_detail.pmid"> -  <a :href="`https://pubmed.ncbi.nlm.nih.gov/${trait_detail.pmid}/`" target="_blank">PubMed</a></span>
      </p>

      <manhattan-plot
        v-if="manhattan_json"
        v-once
        :bin_data="manhattan_json"
        @select_signals="addSignals"
      />

      <h3 v-if="selected_signal_count">
        Selected colocalized signals
        <v-chip
          v-for="signal in selected_signals"
          :key="signal.signal_uuid"
          close
          @click:close="removeSignal(signal)"
        ><em>{{ signal.variant }}</em></v-chip>
        <v-btn
          color="primary"
          small
          style="margin-left: 10px"
          @click="clearSignals"
        >Clear</v-btn>
      </h3>
      <h3 v-else>All colocalized signals</h3>
      <coloc-results-table
        :show_trait1="true"
        :abbrev_trait="true"
        :items="table_data"
        :options.sync="table_options"
        :server-items-length="table_result_count"
        :loading="loading_table"
      />

    </v-container>
  </div>
</template>

<style scoped></style>
