<script>

import { normalizeMarker } from 'locuszoom/esm/helpers/parse';

import LzPlot from '@/components/widgets/LzPlot';
import { fetchJson, url } from '@/util/util';
import {
  config_to_sources,
  get_compare_layout,
  get_region_layout,
  get_region_sources,
  toggle_trait
} from '@/lz-util/layouts';
import TraitLabel from '@/components/widgets/TraitLabel';
import VariantLabel from '@/components/widgets/VariantLabel';
import ColocResultQuery, { coloc_query_url } from '@/mixins/ColocResultQuery';
import ColocResultsTable from '@/components/widgets/ColocResultsTable';
import { NEARBY_DIST } from '@/constants';
import store from '@/store';

function _table_url(study_id, coloc_signal1, filter_data) {
  const base = coloc_query_url.bind(this)(study_id);
  if (!coloc_signal1) {
    // TODO: how should we handle this, scenarios where table isn't ready at page load etc
    return base;
  }
  base.searchParams.set('signal1__trait__uuid', coloc_signal1.trait.uuid);
  base.searchParams.set('signal1__lead_variant_chrom', coloc_signal1.lead_variant_chrom);
  base.searchParams.set('signal1__lead_variant_pos__gte', coloc_signal1.lead_variant_pos - NEARBY_DIST);
  base.searchParams.set('signal1__lead_variant_pos__lte', coloc_signal1.lead_variant_pos + NEARBY_DIST);
  base.searchParams.set('coloc_h4__gte', filter_data.filter_h4);
  base.searchParams.set('r2__gte', filter_data.filter_r2);
  base.searchParams.set('signal1__lead_variant_neg_log_p__gte', filter_data.filter_trait1_logp);
  base.searchParams.set('signal2__lead_variant_neg_log_p__gte', filter_data.filter_trait2_logp);

  return base;
}

function getData(study_id, coloc_result_uuid, filter_data) {
  // These are two separate queries because, in the event of pagination, it's theoretically possible for THIS signal to not appear in TOP_N_nearby signals response
  const this_result = url`/api/studies/${study_id}/coloc/${coloc_result_uuid}/`;

  return fetchJson(this_result)
    .then((coloc) => {
      const { signal1 } = coloc;
      const nearby_results = _table_url(study_id, signal1, filter_data);
      // Final return value reflects two queries, one of which depends on the other
      return Promise.all([
        coloc,
        fetchJson(nearby_results),
      ]);
    });
}

function findPlotRegion(pos1, pos2) {
  const min = Math.min(pos1, pos2);
  const max = Math.max(pos1, pos2);
  const between = max - min;

  let start, end;
  if ((max - min) > NEARBY_DIST) {
    // The 1000 is just to nudge it slightly away from axis boundaries
    start = Math.max(1, min - 1000);
    end = max + 1000;
  } else {
    const remain = (NEARBY_DIST - between) / 2;
    start = Math.max(1, min - remain);
    end = max + remain;
  }

  return { start, end };
}

// API endpoint uses generic prefix "t1" and "t2" to refer to the pair of results we get from this endpoint
const AXIS_OPTIONS = Object.freeze({ MARGINAL: 't1', CONDITIONAL: 't2' });

// Specify which lead variant to use for LD coloring
const LD_OPTIONS = Object.freeze({ SIGNAL1: 'signal1', SIGNAL2: 'signal2' });

/**
 *
 */
export default {
  name: 'ColocResultRegionView',
  data() {
    return {
      coloc_result_data: {},
      loading: true,

      option_ldchoice: LD_OPTIONS.SIGNAL1,
      option_yaxis: AXIS_OPTIONS.CONDITIONAL,

      // LocusZoom configuration for page plots
      layout_region: {}, // get_region_layout('gwas', 'qtl', { chr: 4, start: 89034231, end: 91030144 }),
      sources_region: [], // get_region_sources(1),

      layout_compare: {}, // get_compare_layout('gwas', 'qtl', { chr: 4, start: 89034231, end: 91030144 }),
    };
  },
  computed: {
    signal1() {
      return this.coloc_result_data.signal1;
    },
    signal2() {
      return this.coloc_result_data.signal2;
    },
    table_url() {
      const { signal1 } = this.coloc_result_data;
      const filter_data = store.getters.getFilterData;
      return _table_url.bind(this)(this.$route.params.study_id, signal1, filter_data);
    },
    table_data_filtered() {
      // The "nearby signals" API would inherently include the result already shown on this page.
      //  Hide that from the list of "other" items.
      return this.table_data.filter((item) => item.uuid !== this.$route.params.coloc_id);
    }
  },
  watch: {
    option_yaxis(new_val, old_val) {
      this.toggleMargCond(new_val, old_val);
    },
    option_ldchoice(value) {
      let marker = this.coloc_result_data[value].lead_variant_marker;
      marker = normalizeMarker(marker);

      this.$refs.lzcompare.callPlot((plot) => plot.applyState({ ldrefvar: marker }));
      this.$refs.lzregion.callPlot((plot) => plot.applyState({ ldrefvar: marker }));
    },
    '$route' (to, from) {
      // This code is entered when a route change occurs, such as loading a new colocalization result
      // The component is re-used when such a route change happens and the route uses the same component
      if (this.option_yaxis === AXIS_OPTIONS.MARGINAL) {
        this.$nextTick(() => {
          // Triggers after the next DOM update (after the component has been updated)
          this.toggleMargCond('t1', 't2');
        });
      }
    }
  },
  components: { ColocResultsTable, TraitLabel, VariantLabel, LzPlot },
  mixins: [ColocResultQuery],
  beforeCreate() {
    // Make constants available to component
    this.AXIS_OPTIONS = AXIS_OPTIONS;
    this.LD_OPTIONS = LD_OPTIONS;
  },
  beforeRouteEnter(to, from, next) {
    const filter_data = store.getters.getFilterData;
    getData(to.params.study_id, to.params.coloc_id, filter_data)
      .then(([this_result, nearby_results]) => next((vm) => {
        vm.setData(this_result, nearby_results);
      }));
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    const filter_data = store.getters.getFilterData;
    getData(to.params.study_id, to.params.coloc_id, filter_data)
      .then(([this_result, nearby_results]) => {
        this.setData(this_result, nearby_results);
        next();
      });
  },
  methods: {
    toggleMargCond(new_val, old_val) {
      this.$refs.lzregion.callPlot((plot) => {
        toggle_trait(plot.layout, 'assoc', old_val, new_val);
        plot.applyState();
      });

      this.$refs.lzcompare.callPlot((plot) => {
        toggle_trait(plot.layout, 'trait1', old_val, new_val);
        toggle_trait(plot.layout, 'trait2', old_val, new_val);
        plot.applyState();
      });
    },
    setData(this_result, nearby_results) {
      this.table_data = nearby_results.results;

      this.coloc_result_data = this_result;

      const { signal1, signal2 } = this_result;
      let marker = (this.option_ldchoice === LD_OPTIONS.SIGNAL1 ? signal1 : signal2).lead_variant_marker;
      marker = normalizeMarker(marker);

      const { start, end } = findPlotRegion(signal1.lead_variant_pos, signal2.lead_variant_pos);
      const initialState = {
        chr: signal1.lead_variant_chrom,
        // TODO add a variable for region size throughout app
        start,
        end,
        ldrefvar: marker,
      };

      const { study_id } = this.$route.params;
      let signal2_label = signal2.trait.label;
      if (signal2.trait.metadata.gene) {
        signal2_label += `(${signal2.trait.metadata.gene})`;
      }

      this.layout_compare = get_compare_layout(signal1.trait.label, signal2_label, initialState);

      const source_configs = get_region_sources(
        signal1.trait.genome_build,
        url`/api/studies/${study_id}/signals/${signal1.uuid}/region/`,
        url`/api/studies/${study_id}/signals/${signal2.uuid}/region/`,

        signal1.trait.ld,
      );
      const sources = config_to_sources(source_configs);

      this.layout_region = get_region_layout(
        { id: signal1.uuid, label: signal1.trait.label },
        { id: signal2.uuid, label: signal2_label },
        initialState
      );
      this.sources_region = () => sources; // getter function prevents LZ.DataSources from being wrapped as an observable when passed as a prop

      this.loading = false;
    }
  }
};
</script>

<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto pa-md-3" type="table-tfoot, card"/>
    <v-container v-else :fluid="true">
      <v-row>
        <v-col cols="12">
          <h1>Colocalization of <trait-label class="indigo--text text--darken--4" :trait_data="coloc_result_data.signal1.trait"/> ({{ signal1.lead_variant_marker }})
                  with <trait-label class="indigo--text text--darken--4" :trait_data="coloc_result_data.signal2.trait"/> ({{ signal2.lead_variant_marker }})</h1>
        </v-col>
      </v-row>
      <v-row class="pa-md-3" no-gutters>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-radio-group row v-model="option_ldchoice">
          <template v-slot:label>LD reference:</template>
          <v-radio :value="LD_OPTIONS.SIGNAL1">
            <template v-slot:label>{{ signal1.lead_variant_marker }}</template>
          </v-radio>
          <v-radio v-if="signal1.lead_variant_marker !== signal2.lead_variant_marker" :value="LD_OPTIONS.SIGNAL2">
            <template v-slot:label>{{ signal2.lead_variant_marker }}</template>
          </v-radio>
        </v-radio-group>

        <v-spacer></v-spacer>

        <v-radio-group row v-model="option_yaxis">
          <template v-slot:label>Y-axis:</template>
          <v-radio :value="AXIS_OPTIONS.MARGINAL">
            <template v-slot:label>Marginal -log<sub>10</sub>p</template>
          </v-radio>
          <v-radio :value="AXIS_OPTIONS.CONDITIONAL">
            <template v-slot:label>Conditional -log<sub>10</sub>p</template>
          </v-radio>
        </v-radio-group>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
          <lz-plot
            ref="lzcompare"
            :base_layout="layout_compare" :explicit_sources="sources_region" :show_loading="true"/>
        </v-col>

        <v-col cols="6">
          <lz-plot
            ref="lzregion"
            :base_layout="layout_region" :explicit_sources="sources_region" :show_loading="true"/>
          <span v-if="signal1.lead_variant_neg_log_p === 'Infinity'">
            <span style="font-weight: bold; color: red;">⚠️</span>
            <span style="font-size: 10pt">The lead variant for <trait-label :trait_data="signal1.trait"/> has a p-value of 0, so the -log<sub>10</sub>p value is infinity.
            It cannot be displayed on the plot above.</span>
          </span>
          <span v-if="signal2.lead_variant_neg_log_p === 'Infinity'">
            <span style="font-weight: bold; color: red;">⚠️</span>
            <span style="font-size: 10pt">The lead variant for <trait-label :trait_data="signal2.trait"/> has a p-value of 0, so the -log<sub>10</sub>p value is infinity.
            It cannot be displayed on the plot above.</span>
          </span>
          <span v-if="signal1.cond_minp_variant && (signal1.lead_variant_marker !== signal1.cond_minp_variant)">
            <span class="text-caption">
              ⚠️ The lead variant <variant-label :variant="signal1.lead_variant_marker" size="x-small"/> shown for <trait-label :trait_data="signal1.trait" highlight/> is not
              the most significant variant for this conditionally distinct signal <variant-label :variant="signal1.cond_minp_variant" size="x-small"/>.
              For this trait, the published conditionally distinct variants were used to perform colocalization analysis. The SNP association results for this conditionally distinct signal
              may have been obtained using different software, parameters or LD panels than the published GWAS study.
            </span>
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>All colocalized signals in region</h2>
          <p>Colocalized pairs with GWAS signals within 500kb of the GWAS variant (<variant-label :variant="signal1.lead_variant_marker" :build="signal1.trait.genome_build" size="small"/>) from the originally selected colocalized pair</p>

          <coloc-results-table
            :items="table_data"
            :highlight-signals="[signal1, signal2]"
            :show_trait1="true"
            :abbrev_trait="true"
            :show_ensg="true"
            :options.sync="table_options"
            :server-items-length="table_result_count"
            :loading="loading_table"
          />

          <p style="margin-top: 5px">
            <span style="font-weight: bold">Bold</span> denotes the signals currently being shown in the plots above.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped></style>
