<script>/**
 * Home view of the application
 */
import { fetchJson } from '@/util/util';

function getData() {
  // TODO implement server side pagination as more studies arrive
  return fetchJson('/api/studies/?page_size=5');
}

export default {
  name: 'HomePage',
  data() {
    return {
      loading: true,
      page_data: [],
      result_count: 0,
    };
  },
  beforeRouteEnter (to, from, next) {
    getData().then((page_data) => {
      if (page_data.count === 1) {
        // If there's only one study, just jump directly to the search view
        // This is likely to become the default behavior anyway
        next({ name: 'search', params: { study_id: page_data.results[0].uuid } });
      } else {
        next((vm) => vm.setData(page_data));
      }
    });
  },
  beforeRouteUpdate (to, from) {
    getData()
      .then((page_data) => this.setData(page_data));
  },
  methods: {
    setData(data) {
      this.loading = false;
      this.page_data = data.results;
      this.result_count = data.count;
    }
  }
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <br><br><br>
        <h1 class="text-center">Explore colocalized traits</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2"/>
      <v-col cols="5">
        <p>
          CoLocus shows the results of colocalization analysis for a wide range of phenotypes and molecular traits.
          It can be used to understand the biological supporting evidence for interesting signals, and to better identify potential genes or loci of action.
        </p>
        <p>To begin, select a study from the list to the right.</p>
      </v-col>
      <v-col cols="3" class="studies-list">
        <h2>Browse Studies...</h2>
        <template v-if="!page_data.length">
          No studies found
        </template>
        <p v-else v-for="item in page_data" :key="item.uuid">
          <!-- TODO: Implement server side pagination. It will be a long time before we have more than one study though. -->
          <router-link :to="{name: 'search', params: { study_id: item.uuid } }">
            <strong>{{item.study_name}}</strong>
          </router-link>
          ({{item.trait_count}} traits)

          (<em>{{item.authors}}</em>)
            <v-chip
              v-if="item.pmid"
              link  target="_blank"
              :href="`https://pubmed.ncbi.nlm.nih.gov/${item.pmid}/`"
              color="indigo"
              text-color="white"
            >PMID: {{item.pmid}}</v-chip>
        </p>
      </v-col>
      <v-col cols="2"/>
    </v-row>

  </v-container>
</template>

<style>
  .studies-list {
    background-color: #DDDDDD;
    min-height: 250px;
  }
</style>
