<script>
import { create_gwas_plot } from '@/vis/pheweb_plots';

/**
 * Render a manhattan plot
 */
export default {
  name: 'ManhattanPlot',
  props: {
    // Binned manhattan plot data from manhattan.json + extra "is_colocalized" field (probably added serverside)
    bin_data: { type: Object, default: () => ({}) },
    tooltip_template: {
      type: String,
      default: `<b><%- d.chrom %>:<%- d.pos.toLocaleString() %> <%- (d.ref && d.alt) ? (d.ref + "/" + d.alt) : "" %></b><br>
                -log<sub>10</sub>(p): <%- d.neg_log_pvalue && (+d.neg_log_pvalue).toFixed(3) %><br>
                <%- d.qtl_genes && d.qtl_genes.length ? 'QTL gene(s): ' + d.qtl_genes.map(function(gene) { return gene.symbol; }).join(", ") : "" %>
                <% if (d.qtl_genes && d.qtl_genes.length) { print('<br>'); } %>
                Nearest gene(s): <%- d.nearest_genes && d.nearest_genes.length && d.nearest_genes.map(function(gene) { return gene.symbol; }).join(", ") %>`
    },

    // urlprefix  // pheweb uses this param but we're going to go a different way in how we handle clicking on a point
  },
  data() {
    return {
      // When a manhattan plot gene is clicked, notify the external page to drive filters in table / rendering.
      onclick_signals: (signals) => this.$emit('select_signals', signals),
    };
  },
  mounted() {
    let { bin_data } = this;
    bin_data = JSON.parse(JSON.stringify(bin_data));
    create_gwas_plot(this.$refs.manhattan_plot_container, this.tooltip_template, bin_data.variant_bins, bin_data.unbinned_variants, this.onclick_signals);
  }
};
</script>

<template>
  <div ref="manhattan_plot_container" class="manhattan-plot"></div>
</template>

<style>
  .d3-tip {
    line-height: 1.4;
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    pointer-events: none;
  }

  /* Creates a small triangle extender for the tooltip */
  .d3-tip:after {
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    position: absolute;
    pointer-events: none;
  }

  /* Northward tooltips */
  .d3-tip.n:after {
    content: "\25BC";
    margin: -3px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
  }

  .manhattan-plot {
    min-width: 700px;
  }

  .manhattan-plot .axis > path.domain {
    stroke-width: 2px;
    stroke: #666;
    fill: none;
  }

  .manhattan-plot .axis g.tick line {
    stroke: #666;
  }
</style>
