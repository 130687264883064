import { fetchJson } from '@/util/util';
import { coloc_results_list } from '@/util/urls';

/**
 * Mixin that enables querying colocalization results. Used in conjunction with `ColocResultsTable`
 *
 * Many views show a table of signals (whether genome wide or in a region). That table can be sorted or filtered in ways that
 */
export default {
  data() {
    return {
      // Configure search result table, including parameters that are used to control query
      table_data: [],
      table_options: { sortBy: [], sortDesc: [], page: 1 },
      loading_table: false,
      table_result_count: 0,
    };
  },
  computed: {
    table_url() {
      // Individual pages will probably override this based on additional filters (region, trait, etc)
      // Default mixin assumes that we are filtering to just study ID and that ID is part of the route
      return coloc_query_url.bind(this)(this.$route.params.study_id);
    }
  },
  watch: {
    table_url() {
      // Re-fetch page data every time search URL changes
      this.fetchTableData();
    }
  },
  methods: {
    // This method can't be used in route guards, but an inherent assumption is that we are comfortable letting the
    //   rest of the page load, then rendering the table asynchronously as data arrives
    fetchTableData() {
      // Todo handle errors- display etc
      this.loading_table = true;

      return fetchJson(this.table_url)
        .then((resp) => {
          this.table_data = resp.results;
          this.table_result_count = resp.count;
        }).finally(() => {
          this.loading_table = false;
        });
    }
  }
};

/**
 * Most users of this mixin will want to build on the basic url with additional query params, so this function is exported as a helper
 *   To use this method in a mixin class, use `coloc_query_url.bind(this)(params)`
 *
 *   If used as a standalone method (like before the route is loaded), it will always assume we are fetching default table options (page 1 etc)
 * @param study_id
 * @return {module:url.URL}
 */
function coloc_query_url(study_id) {
  const base = coloc_results_list(study_id);
  const { table_options = {} } = this || {};
  const { page = 1, sortBy = [], sortDesc = [] } = table_options;

  // Pagination is entirely handled by the API and table can notify what data to show
  if (page !== 1) {
    base.searchParams.set('page', page);
  }

  const newOrdering = [];
  for (const [i, e] of sortBy.entries()) {
    const sort_order = sortDesc[i] ? '-' : '';
    if (e.includes('lead_variant_marker')) {
      const signal = e.includes('signal1') ? 'signal1' : 'signal2';
      const ordering = `${signal}__lead_variant_chrom,${sort_order}${signal}__lead_variant_pos`;
      newOrdering.push(ordering);
    } else {
      const sort_field = e.replaceAll('.', '__');
      newOrdering.push(`${sort_order}${sort_field}`);
    }
  }

  if (newOrdering.length > 0) {
    base.searchParams.set('ordering', newOrdering.join(','));
  }

  return base;
}

export { coloc_query_url };
