<template>
  <v-app>
    <v-app-bar
      app
      color="grey lighten-5"
      dark
      dense
    >
      <v-app-bar-nav-icon v-if="isFilterButtonVisible" @click="toggleFilterPanel" color="red"></v-app-bar-nav-icon>

      <AppLogo />

      <v-spacer></v-spacer>

      <a href="https://forms.gle/9idPsYcUcKDBqS8s6" target="_blank" rel="noopener noreferrer">
        <v-btn color="primary" dark small tile>
          Feedback
        </v-btn>
      </a>
    </v-app-bar>

    <v-main class="v-main">
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import PageFooter from './components/widgets/PageFooter.vue';
import AppLogo from '@/components/widgets/AppLogo.vue';

export default {
  name: 'App',
  components: { AppLogo, PageFooter },
  data: () => ({
  }),
  methods: {
    ...mapActions(['toggleFilterPanel'])
  },
  computed: {
    ...mapGetters(['isFilterButtonVisible'])
  }
};
</script>

<style scoped>
.v-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  overflow: auto; /* Add scrolling if content overflows */
  min-height: calc(100vh - 50px); /* Adjust the 50px to the height of your footer */
}

</style>
