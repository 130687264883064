import Vue from 'vue';
import Vuex from 'vuex';
import filterPanel from './modules/filter_panel';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    filterPanel
  }
});
