import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Search from '../views/SearchView';
import ColocResultRegionView from '../views/ColocResultRegionView';
import TraitDetailView from '../views/TraitDetailView';
import TraitGeneRegionView from '../views/TraitGeneRegionView';
import TraitManhattanSignalsView from '../views/TraitManhattanSignalsView';
import StudyDetailView from '@/views/StudyDetailView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' },
  },
  {
    path: '/studies/:study_id/',
    name: 'study_detail',
    component: StudyDetailView,
    meta: { title: 'Study detail' },
  },
  {
    // TODO: In the future create a separate api endpoint and UI to expose cross-study search. (right now everything runs through one study) Do one thing well first.
    path: '/studies/:study_id/search/',
    name: 'search',
    component: Search,
    meta: { title: 'Search' },
  },
  // {
  //   path: '/studies/:study_id',  // Consider whether to represent this asa separate route or combined with search view; for now do latter. Description of study parameters, but list of signals is a subset of the search view.
  //   name: 'studies-detail',
  //   component: StudiesDetail,
  // },
  {
    // Show trait data- link, details, etc
    path: '/studies/:study_id/trait/:trait_id',
    name: 'trait_detail',
    component: TraitDetailView,
    meta: {
      title: (params) => `Trait: ${params.trait_id}`,
    }
  },
  {
    // Show all possible signal pairs relative to a particular GWAS. Restrict this view to if trait1 = gwas.
    path: '/studies/:study_id/trait/:trait_id/signals',
    name: 'one_trait_all_signals',
    component: TraitManhattanSignalsView,
    meta: {
      title: (params) => `Signals for trait: ${params.trait_id}`,
    },
  },
  {
    // Also requires a query param of either <gene> or <chrom, start, end>
    path: '/studies/:study_id/trait/:trait_id/region/',
    name: 'one_trait_summarize_region',
    component: TraitGeneRegionView,
    meta: {
      title: (params, query) => {
        if (query.gene) {
          return `Summary for ${query.gene}`;
        } else {
          return `Summary for region ${query.chrom}:${query.start}-${query.end}`;
        }
      },
    }
  },
  {
    path: '/studies/:study_id/coloc/:coloc_id',
    name: 'one_signal',
    component: ColocResultRegionView,
    meta: {
      // TODO Would be nice to shift this over to nested URLs to produce DRY code. Experiment.
      title: (params) => `Colocalization results: ${params.coloc_id}`,
    }
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'About' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.afterEach((to, from) => {
  // Title can be string or a function that receives route params
  const base = 'CoLocus Browser';
  let prefix = to.meta.title || '';

  if (typeof prefix === 'function') {
    prefix = prefix(to.params, to.query);
  }

  document.title = !prefix ? base : `${prefix} | ${base}`;
});

export default router;
